import React from 'react'

import Page from '../../containers/PickOneGame'
import Seo from '../../containers/Seo'

const Game = (props) => {
  return (
    <>
      <Seo.Giloo />
      <Page {...props} />
    </>
  )
}

export default Game
