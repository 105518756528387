import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from '../../components/Link'
import { responsive } from '../../components/ThemeProvider/theme'
import GameContainer from './GameContainer'

const PickOneGame = ({ location }) => {
  return (
    <GameContainer bg="giloo.yellow">
      <StaticImage src="giloo/giloo.png" alt="你的靈魂裡住著哪位名人？" />
      <Link to={`${location.pathname}play/`} maxWidth={responsive('14em', '50%')}>
        <StaticImage src="giloo/首頁按鈕.png" alt="一起找尋你的名人魂" />
      </Link>
    </GameContainer>
  )
}

export default PickOneGame
